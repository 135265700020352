import React, { useState } from 'react';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import './App.css'; // Import the updated CSS
import fareMdIcon from './appIcon.png';

function App() {
  const [pdfFile, setPdfFile] = useState(null);
  const [text, setText] = useState('');
  const [completion, setCompletion] = useState('');
  const [downloadStatus, setDownloadStatus] = useState(''); // Message for download status
  const [error, setError] = useState(''); // Error message
  const [userChoice, setUserChoice] = useState('file'); // Default choice is 'file'
  const [processing, setProcessing] = useState(false); // Track whether processing is ongoing
  const [showInput, setShowInput] = useState(true); // Show/hide text input
  const [showNewRecordButton, setShowNewRecordButton] = useState(false); // Show/hide "Code A New Record" button

  // Function to set a message
  const setMessage = (message) => {
    setDownloadStatus(message);
  };

  // Function to set an error message
  const setErrorMsg = (errorMsg) => {
    setError(errorMsg);
    setDownloadStatus(''); // Clear download status message if there's an error
    setProcessing(false); // Stop processing on error
  };

  const handleFileChange = (event) => {
    setPdfFile(event.target.files[0]);
    // Clear text and completion when a new file is chosen
    setText('');
    setCompletion('');
  };

  const handleUpload = async () => {
    try {
      if (userChoice === 'file') {
        if (!pdfFile) {
          setErrorMsg('Please choose a file for upload.');
          return;
        }

        const formData = new FormData();
        formData.append('file', pdfFile);

        // Clear text and completion when a new file is uploaded
        setText('');
        setCompletion('');
        setProcessing(true); // Start processing
        setShowInput(false); // Hide text input while processing

        // Upload PDF to Flask backend and parse text
        const response = await axios.post('https://api.faremd.com:443/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setText("Your record will appear below to download either as a pdf or text document. Thank you");

        // Use OpenAI to complete the prompt
        const openaiResponse = await axios.post('https://api.faremd.com:443/complete', {
          prompt: response.data.text,
        });

        if (openaiResponse.data.error) {
          setErrorMsg('Error generating medical codes. Please try again later.'); // Set OpenAI error message
        } else {
          setCompletion(openaiResponse.data.completion);
          setShowNewRecordButton(true); // Show "Code A New Record" button
        }

        setProcessing(false); // Stop processing
      } else if (userChoice === 'text') {
        if (!text) {
          setErrorMsg('Please enter text for medical coding.');
          return;
        }

        // Clear text and completion when text input is submitted
        setText('');
        setCompletion('');
        setProcessing(true); // Start processing
        setShowInput(false); // Hide text input while processing

        // Use OpenAI to complete the prompt directly from the user's input text
        const openaiResponse = await axios.post('https://api.faremd.com:443/complete', {
          prompt: text,
        });

        if (openaiResponse.data.error) {
          setErrorMsg('Error generating medical codes. Please try again later.'); // Set OpenAI error message
        } else {
          setCompletion(openaiResponse.data.completion);
          setShowNewRecordButton(true); // Show "Code A New Record" button
        }

        setProcessing(false); // Stop processing
      }
    } catch (error) {
      setErrorMsg('Error processing the file. Please try again.'); // Set error message
      console.error('Error:', error);
      setProcessing(false); // Stop processing on error
    }
  };

  const handleDownload = () => {
    try {
      // Create a Blob with the completion text
      const blob = new Blob([completion], { type: 'text/plain' });

      // Create a link to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'result.txt';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setMessage('Download successful!'); // Set success message
    } catch (error) {
      setErrorMsg('Error downloading file. Please try again.'); // Set error message
      console.error('Error:', error);
    }
  };

  const handleDownloadPDF = () => {
    try {
      // Convert completion text to a PDF file
      const pdfElement = document.createElement('div');
      pdfElement.innerText = completion;

      html2pdf(pdfElement, {
        margin: 10,
        filename: 'result.pdf',
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
      });

      setMessage('Download successful!'); // Set success message
    } catch (error) {
      setErrorMsg('Error generating PDF. Please try again.'); // Set error message
      console.error('Error:', error);
    }
  };

  const handleCodeNewRecord = () => {
    // Clear all states and reset the form for a new record
    setPdfFile(null);
    setText('');
    setCompletion('');
    setDownloadStatus('');
    setError('');
    setUserChoice('file'); // Reset to file upload option
    setShowInput(true); // Show text input again
    setShowNewRecordButton(false); // Hide "Code A New Record" button
  };

  return (
    <div>
      <header className="header">
        <img src={fareMdIcon} alt="FareMD Icon" className="icon" /> {/* Icon image */}
      </header><br></br>
      <h1>FareMD - Upload your EHR/EMR PDF or Enter Text</h1>
      <div>
        <label>
          <input
            type="radio"
            value="file"
            checked={userChoice === 'file'}
            onChange={() => setUserChoice('file')}
          />
          Upload a File
        </label>
        <label>
          <input
            type="radio"
            value="text"
            checked={userChoice === 'text'}
            onChange={() => setUserChoice('text')}
          />
          Enter Text
        </label>
      </div>
      {userChoice === 'file' && (
        <div>
          <input type="file" accept=".pdf" onChange={handleFileChange} />
        </div>
      )}
      {userChoice === 'text' && showInput && !processing && (
        <div>
          <textarea
            rows="25"
            cols="100"
            placeholder="Enter text for medical coding..."
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      )}
      <button onClick={handleUpload} disabled={processing}>
        {userChoice === 'text' ? 'Complete' : 'Upload and Complete'}
      </button>
      {processing && <div className="message">Processing...</div>}
      {text && !processing && (
        <div>
          <pre>Your record will appear below to download either as a pdf or text document. Thank you</pre>
        </div>
      )}
      {completion && !processing && (
        <div>
          <h2>Output Text:</h2>
          <pre>{completion}</pre>
          <button onClick={handleDownload}>Download Result (Text)</button>
          <button onClick={handleDownloadPDF}>Download Result (PDF)</button>
        </div>
      )}
      {downloadStatus && <div className="message">{downloadStatus}</div>}
      {error && <div className="error">{error}</div>}
      {showNewRecordButton && (
        <button onClick={handleCodeNewRecord}>Code A New Record</button>
      )}
    </div>
  );
}

export default App;

